<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <form @submit.prevent="getOrdersByOrderStatus">

                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <div class="row">
                                            <label>Order Status</label>
                                            <select class="form-control" v-model="orderStatus" name="accountType" id="accountType" v-validate="'required'" @change="getOrdersByOrderStatus()" >
                                                <option value="ALL">ALL</option>
                                                <option value="COMPLETED">COMPLETED</option>
                                                <option value="ONGOING">ONGOING</option>
                                                <option value="DELAYED">DELAYED</option>
                                                <option value="PENDING_CONFIRMATION">PENDING CONFIRMATION</option>
                                                <option value="ARRIVED_AT_PICKUP">ARRIVED AT PICKUP</option>
                                                <option value="ARRIVED_AT_DESTINATION">ARRIVED AT DESTINATION</option>
                                                <option value="CUSTOMER_CANCELLED">CUSTOMER CANCELLED</option>
                                                <option value="SYSTEM_CANCELLED">SYSTEM CANCELLED</option>
                                            </select>
                                            <span v-show="errors.has('orderStatus')" class="help text-danger">{{ errors.first('orderStatus') }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="form-group mb-3">
                                        <label ></label>
                                        <br />
                                        <button class="btn btn-primary btn-block"  type="submit" style="margin-top: 8px;" >
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div>
                            <lottie-loader :loading="showLoader" />
                        </div>
                        <p class="text-muted font-13 mb-4"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-right"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0" id="table">
                            <b-table
                                    table-class="table table-centered w-100"
                                    thead-tr-class="bg-light"
                                    :items="listOfOrders"
                                    :fields="tableFields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >

                                <template #cell(orderNo)="data">
                                    <span class="font-12 font-weight-bold text-dark">
                                     <router-link class="mr-2" style="cursor: pointer; border-bottom: 2px dotted #029f96;" :to="'/pharmacy/order/'+data.item.id">{{ data.item.orderNo}}</router-link>
                                    </span>
                                </template>
                                <template v-slot:cell(status)="data">
                                      <span
                                              class="p-1 badge badge-soft-success"
                                              :class="{
                                          'badge-soft-danger':
                                            data.item.status === 'PENDING_CONFIRMATION',
                                          'badge-soft-primary':
                                            data.item.status === 'APPROVED_CONFIRMATION',
                                        }">
                                        {{ data.item.status }}</span
                                      >
                                </template>
                                <template #cell(paymentMethod)="data">
                                      <span
                                          class="p-1 badge"
                                                  :class="{
                                              'badge-success': data.item.paymentMethod === 'CASH',
                                              'badge-info': data.item.paymentMethod === 'MOMO',
                                            }">
                                            {{ data.item.paymentMethod }}
                                      </span>
                                </template>
                                <template #cell(price)="data">
                                    <span class="badge badge-outline-info p-1"> {{ data.item.price}}</span>
                                </template>
                                <template #cell(totalPrice)="data">
                                    <span class="badge badge-outline-success p-1"> {{ data.item.totalPrice}}</span>
                                </template>
                                <template #cell(deliveryPrice)="data">
                                    <span class="badge badge-outline-secondary p-1"> {{ data.item.deliveryPrice}}</span>
                                </template>
<!--                                <template #cell(discountedPrice)="data">-->
<!--                                    <span class="badge badge-outline-danger p-1"> {{ data.item.discountedPrice}}</span>-->
<!--                                </template>-->
<!--                                <template #cell(quantity)="data">-->
<!--                                    <span class="text-danger font-weight-semibold"> {{ data.item.quantity}}</span>-->
<!--                                </template>-->
                                <template #cell(dateOrdered)="data">

                                    <span class="font-12 font-weight-bold text-dark"> {{ data.item.dateOrdered | moment("MMMM Do YYYY") }}</span>
                                </template>
<!--                                <template #cell(actions)="data">-->

<!--                                    <router-link class="btn btn-primary mr-2" style="cursor: pointer" :to="'/pharmacy/order/'+data.item.id"> <i class="mdi mdi-eye mx-1"></i>View</router-link>-->
<!--                                </template>-->
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                        class="dataTables_paginate paging_simple_numbers float-right"
                                >
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/Page-header";
    import BaseUrl from "../../../components/constants";
    import ToastConfigAlert from "../../../../ToastConfig";
    import LottieLoader from "../../../components/LottieLoader";

    export default {
        name: "ManageOrders",
        components : {
            Layout,
            PageHeader,
            LottieLoader
        },
        data() {
            return {
                orderStatus:'ALL',
                title: "Grocery orders",
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "orders",
                        active: true,
                    },
                ],
                showLoader :false,
                listOfOrders :[],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortDesc: true,
                sortBy: "id",
                tableFields: [
                    {
                        thClass: 'd-none', tdClass: 'd-none',
                        key: "id",
                        sortable: true,
                    },
                    {
                        label:"Order date",
                        key: "dateOrdered",
                        sortable: true,
                    },
                    {
                        label:"Customer Name",
                        key: "fullName",
                        sortable: true,
                    },
                    {
                        label:"Customer Mobile Number",
                        key: "phoneNumber",
                        sortable: true,
                    },
                    {
                        label:"Order number",
                        key: "orderNo",
                        sortable: true,
                    },
                    // {
                    //     label:"Discount amount",
                    //     key: "discountedPrice",
                    //     sortable: true,
                    // },
                    {
                        label:"Total price",
                        key: "totalPrice",
                        sortable: true,
                    },
                    // {
                    //     label:"Quantity",
                    //     key: "quantity",
                    //     sortable: true,
                    // },
                    {
                        label :"Payment method",
                        key: "paymentMethod",
                        sortable: true,
                    },
                    {
                        label:"Order status",
                        key: "status",
                        sortable: true,
                    },
                ],
            }
        },
        methods : {
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            getOrdersByOrderStatus() {

                this.$validator.validateAll().then((result) => {
                    if (result) {

                        this.showLoader = true;
                        BaseUrl.AxiosBearer.get(`/admin/orders/get-orders?classification=GROCERIES&status=${this.orderStatus}&page=1&pageSize=1000000`).then((resp) => {
                            this.showLoader = false
                            if(resp.data.status) {
                                this.listOfOrders = []

                                resp.data.payload.forEach((data) => {
                                    this.listOfOrders.push({
                                        id : data.id,
                                        orderNo : `#${data.orderNumber}`,
                                        status : data.status,
                                        // price : "GHS "+ BaseUrl.formatMoney(data.price,'GHS'),
                                        // deliveryPrice :"GHS "+ BaseUrl.formatMoney(data.deliveryPrice,'GHS'),
                                        // discountedPrice :"GHS "+ BaseUrl.formatMoney(data.discountedPrice,'GHS'),
                                        totalPrice : "GHS " +BaseUrl.formatMoney(data.totalPrice,'GHS'),
                                        // quantity : data.quantity,
                                        dateOrdered : data.orderedAt,
                                        paymentMethod : data?.paymentMethod,
                                        fullName : data.patient.identity.surname + ' '+data.patient.identity.othernames,
                                        phoneNumber : data.patient.identity.phoneNumber

                                    })
                                })
                            }

                        }).catch((error) => {
                            this.$bvToast.toast(error, ToastConfigAlert)
                        })

                    }
                })

            }
        },
        created() {
            this.getOrdersByOrderStatus()
        },
        computed: {
            rows() {
                return this.listOfOrders.length;
            },
        },
    }
</script>

<style scoped>

</style>